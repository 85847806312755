<template>
  <div>
    <meeting-tag-add-new
        :is-add-new-meeting-tag-sidebar-active.sync="isAddNewMeetingTagSidebarActive"
        :id = "id"
        :data="meetingtagData"
        @refetch-data="refetchData"
        v-if="ability.can('create','meetingtags')"
    >

    </meeting-tag-add-new>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
              <b-button
                variant="primary"
                @click="handleCreate"
              >
                <span class="text-nowrap">Add Meeting Tag</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

    <b-table
        ref="refMeetingTagListTable"
        class="position-relative"
        :items="fetchMeetingTags"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
    >
    <template #cell(description)="data">
      <div v-html="data.item.description"></div>
    </template>

    <template #cell(demo)="data">
      <b-badge class="text-capitalize"
      :style="{background:data.item.background_color, color: data.item.font_color}"
      >
        {{ data.item.tag }}
      </b-badge>
    </template>

    <template #cell(action)="data">
        <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="ability.can('update','meetingtags')" @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
    </template>
    </b-table>  
    <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMeetingTags"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import meetingtagStoreModule from "../meetingtagStoreModule";
import useMeetingTagsList from "./useMeetingTagsList";
import MeetingTagAddNew from "./MeetingTagListAddNew.vue"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    MeetingTagAddNew,
  },
  methods:{
      handleCreate(){
          this.id = 0
          this.resetMeetingTagData()
          this.isAddNewMeetingTagSidebarActive = true
      },
      handleEdit(item){
        this.id = item.id
        this.meetingtagData.id = item.id
        this.meetingtagData.tag = item.tag
        this.meetingtagData.description = item.description
        this.meetingtagData.background_color = item.background_color
        this.meetingtagData.font_color = item.font_color
        this.isAddNewMeetingTagSidebarActive = true
        
      }, resetMeetingTagData(){
        this.meetingtagData = {
            id: 0,
            tag: '',
            description: '#A5853D',
            background_color: '#FFFFFF',
        }
      }
  },
  data(){
    return {
        id:0,
        meetingtagData:{},
    }  
  },
  setup() {
    const SETTING_MEETING_TAG_STORE_MODULE_NAME = "setting-meetingtag";

    if (!store.hasModule(SETTING_MEETING_TAG_STORE_MODULE_NAME))
      store.registerModule(
        SETTING_MEETING_TAG_STORE_MODULE_NAME,
        meetingtagStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(SETTING_MEETING_TAG_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_MEETING_TAG_STORE_MODULE_NAME);
    });

    const isAddNewMeetingTagSidebarActive = ref(false);

    const {
      fetchMeetingTags,
      tableColumns,
      perPage,
      currentPage,
      totalMeetingTags,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMeetingTagListTable,
      refetchData,
      ability,
    } = useMeetingTagsList();

    return {
      //Sidebar
      isAddNewMeetingTagSidebarActive,

      fetchMeetingTags,
      tableColumns,
      perPage,
      currentPage,
      totalMeetingTags,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMeetingTagListTable,
      refetchData,
      avatarText,
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.badge {
  width:100%;
}
</style>